import Head from 'next/head'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PUBLIC_APP_NAME } from '~/core/constants/env'

export function nonAccentVietnamese(str: string) {
  if (!str || typeof str !== 'string') {
    return ''
  }
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}
export const removeEndSplash = (link: string) => link?.replace(/\/$/g, '')

const HeadMetaTags: FC<{
  title: string
  description?: string
  keywords?: string
  url?: string
  image?: string
  noUseHrefLang?: boolean
  image_alt?: string
  alternateLink?: { url: string; lang: string }
  hideFavicon?: boolean
}> = ({
  title,
  description,
  url,
  keywords,
  image,
  alternateLink,
  image_alt,
  hideFavicon = false
}) => {
  const { i18n } = useTranslation()

  return (
    <Head>
      <title>{title}</title>
      {url && <link rel="canonical" href={url} />}
      <meta content={i18n.language} httpEquiv="content-language" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      {keywords && <meta itemProp="keywords" content={keywords} />}
      <meta itemProp="name" content={title} />
      {description && <meta itemProp="description" content={description} />}
      {image && <meta itemProp="image" content={image} />}

      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}

      {url && <meta property="og:url" content={removeEndSplash(url)} />}
      <meta property="robots" content="noodp,index,follow" />
      <meta property="og:type" content="website" />
      <meta
        property="fb:app_id"
        content={`${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}`}
      />

      {image && <meta name="twitter:card" content={image} />}
      {PUBLIC_APP_NAME && (
        <meta name="twitter:site" content={`@${PUBLIC_APP_NAME}`} />
      )}
      {image && <meta name="twitter:image" content={image} />}
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {alternateLink && (
        <link
          rel="alternate"
          href={`${alternateLink.url}`}
          hrefLang={alternateLink.lang}
        />
      )}

      <meta property="og:site_name" content={title} />
      {image && (
        <>
          <meta property="og:image:secure_url" content={image} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:alt" content={image_alt} />
        </>
      )}

      {!hideFavicon && <link rel="icon" href="/favicon.ico" />}
    </Head>
  )
}

export default HeadMetaTags
