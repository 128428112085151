import {
  canActionSetting,
  PERMISSIONS_LIST
} from '~/core/utilities/feature-permission'
import useBoundStore from '~/lib/store'

const usePermissionSetting = () => {
  const { currentRole, permissionSetting } = useBoundStore()

  const canAccessModule = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule]
  })

  const actionCustomField = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.profile_custom_field
        .keyModuleObject
    ]
  })

  const actionTag = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.tag.keyModuleObject
    ]
  })

  const actionLocation = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.location.keyModuleObject
    ]
  })

  const actionPipelineTemplate = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.template_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.template_setting.objects.pipeline_template
        .keyModuleObject
    ]
  })

  const canAccessRequisition = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [
      PERMISSIONS_LIST.manage_requisition.keyModule,
      PERMISSIONS_LIST.request_requisition.keyModule
    ]
  })

  const canAccessReport = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.report.keyModule]
  })

  const actionUser = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.user_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.user_management.objects.user.keyModuleObject,
      PERMISSIONS_LIST.user_management.objects.member_invitation.keyModuleObject
    ]
  })

  const actionReferral = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.referral.keyModuleObject
    ]
  })

  const actionCareer = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.career_page.keyModule]
  })

  const actionCompanyStatus = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.company_status.keyModuleObject
    ]
  })

  const actionDepartment = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.department.keyModuleObject
    ]
  })

  const actionRejectedReason = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.rejected_reason.keyModuleObject
    ]
  })

  const actionEmailTemplate = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.template_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.template_setting.objects.email_template.keyModuleObject
    ]
  })

  const actionIkitTemplate = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.template_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.template_setting.objects.ikit_template.keyModuleObject
    ]
  })

  const actionProfileTemplate = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.template_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.template_setting.objects.cv_template.keyModuleObject
    ]
  })

  const actionRequisition = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.requisition.keyModuleObject
    ]
  })

  const actionWorkSpace = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.tenant_setting.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.tenant_setting.objects.tenant_profile.keyModuleObject
    ]
  })

  const actionManageRequisition = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.manage_requisition.keyModule]
  })

  const actionRequestRequisition = canActionSetting({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.request_requisition.keyModule]
  })

  return {
    canAccessModule,
    canAccessRequisition,
    canAccessReport,
    actionCustomField,
    actionTag,
    actionLocation,
    actionPipelineTemplate,
    actionUser,
    actionReferral,
    actionCareer,
    actionCompanyStatus,
    actionDepartment,
    actionRejectedReason,
    actionEmailTemplate,
    actionIkitTemplate,
    actionProfileTemplate,
    actionRequisition,
    actionWorkSpace,
    actionManageRequisition,
    actionRequestRequisition
  }
}

export default usePermissionSetting
