// eslint-disable-next-line import/no-restricted-paths
import { ITenantFeatureSettingType } from '~/lib/store/feature-setting-slice'
// eslint-disable-next-line import/no-restricted-paths
import { ITenantPermissionSettingType } from '~/lib/store/permission-setting-slice'
import { IRole } from '../@types/global'
import { IMPORT_JOB, REFERRAL, REQUISITION } from '../constants/enum'
import { adminCanAction } from './permission'

const DROPDOWN_PERMISSIONS = { full: 'full', owned: 'owned' }
const ACTIONS_PERMISSIONS = {
  show: 'show',
  create: 'create',
  update: 'update',
  owned_update: 'owned_update',
  delete: 'delete',
  owned_delete: 'owned_delete'
}
const PERMISSIONS_LIST = {
  job_management: {
    keyModule: 'job_management',
    kind: 'accessibility',
    objects: {
      profile: { keyModuleObject: 'profile' },
      talent_pool: { keyModuleObject: 'talent_pool' },
      talent_pool_note: { keyModuleObject: 'talent_pool_note' },
      job: { keyModuleObject: 'job' },
      job_note: { keyModuleObject: 'job_note' },
      profile_note: { keyModuleObject: 'profile_note' },
      applicant_note: { keyModuleObject: 'applicant_note' },
      applicant: { keyModuleObject: 'applicant' },
      profile_task: { keyModuleObject: 'profile_task' },
      applicant_task: { keyModuleObject: 'applicant_task' },
      interview: { keyModuleObject: 'interview' },
      job_ikit: { keyModuleObject: 'job_ikit' },
      job_ikit_evaluation: { keyModuleObject: 'job_ikit_evaluation' }
    }
  },
  company_management: {
    keyModule: 'company_management',
    kind: 'accessibility',
    objects: {
      company: { keyModuleObject: 'company' },
      company_note: { keyModuleObject: 'company_note' },
      company_task: { keyModuleObject: 'company_task' },
      company_contact: { keyModuleObject: 'company_contact' }
    }
  },
  placement: {
    keyModule: 'placement',
    kind: 'accessibility',
    objects: {
      placement: { keyModuleObject: 'placement' },
      profit_split: { keyModuleObject: 'profit_split' }
    }
  },
  career_page: {
    keyModule: 'career_page',
    kind: 'enabling',
    objects: {
      career_page: { keyModuleObject: 'career_page' }
    }
  },
  tenant_setting: {
    keyModule: 'tenant_setting',
    kind: 'enabling',
    objects: {
      tenant_profile: { keyModuleObject: 'tenant_profile' },
      tag: { keyModuleObject: 'tag' },
      referral: { keyModuleObject: 'referral' },
      requisition: { keyModuleObject: 'requisition' },
      rejected_reason: { keyModuleObject: 'rejected_reason' },
      profile_custom_field: { keyModuleObject: 'profile_custom_field' },
      location: { keyModuleObject: 'location' },
      department: { keyModuleObject: 'department' },
      company_status: { keyModuleObject: 'company_status' }
    }
  },
  user_management: {
    keyModule: 'user_management',
    kind: 'enabling',
    objects: {
      user: { keyModuleObject: 'user' },
      user_profile: { keyModuleObject: 'user_profile' },
      member_invitation: { keyModuleObject: 'member_invitation' }
    }
  },
  template_setting: {
    keyModule: 'template_setting',
    kind: 'enabling',
    objects: {
      email_template: { keyModuleObject: 'email_template' },
      pipeline_template: { keyModuleObject: 'pipeline_template' },
      ikit_template: { keyModuleObject: 'ikit_template' },
      cv_template: { keyModuleObject: 'cv_template' }
    }
  },
  report: {
    keyModule: 'report',
    kind: 'enabling',
    objects: {
      report: { keyModuleObject: 'report' }
    }
  },
  manage_requisition: {
    keyModule: 'manage_requisition',
    kind: 'enabling',
    objects: {}
  },
  request_requisition: {
    keyModule: 'request_requisition',
    kind: 'enabling',
    objects: {}
  }
}

const DEFAULT_PERMISSIONS_LIST = {
  create: false,
  update: false,
  owned_update: false,
  delete: false,
  owned_delete: false
}

interface IDefaultPermissionsList {
  create: boolean
  update: boolean
  delete: boolean
  owned_update?: boolean
  owned_delete?: boolean
}

const canAccessSetting = ({
  permissionSetting,
  keyModule = []
}: {
  permissionSetting: ITenantPermissionSettingType[]
  keyModule: Array<string>
}) => {
  const findModules = (permissionSetting || []).filter((fi) =>
    keyModule.includes(fi.moduleName)
  )

  return findModules?.filter((item) => item.enabled)?.[0]?.enabled
}

const canActionSetting = ({
  currentRole,
  permissionSetting,
  keyModule = [],
  keyModuleObject = []
}: {
  currentRole?: IRole
  permissionSetting: ITenantPermissionSettingType[]
  keyModule: Array<string>
  keyModuleObject?: Array<string>
}) => {
  if (adminCanAction(currentRole?.code)) {
    return true
  }

  const findModules = (permissionSetting || []).filter((fi) =>
    keyModule.includes(fi.moduleName)
  )

  if (keyModuleObject.length === 0) {
    return findModules?.filter((item) => item.enabled)?.[0]?.enabled
  } else {
    const findModule = findModules.filter((item) => item.enabled)

    let result = false
    for (let i = 0; i < findModule.length; i++) {
      const item = findModule?.[i]
      if (item?.objects?.length) {
        const filterItem = item.objects.filter(
          (fi) => keyModuleObject.includes(fi.name) && fi.enabled
        )
        if (filterItem?.length) {
          result = true
          break
        }
      }
    }
    return result
  }
}

const canPermissionFeature = ({
  currentRole,
  permissionSetting,
  keyModule = [],
  keyModuleObject = [],
  permissionAction
}: {
  currentRole?: IRole
  permissionSetting: ITenantPermissionSettingType[]
  keyModule: Array<string>
  keyModuleObject: Array<string>
  permissionAction: string
}) => {
  if (adminCanAction(currentRole?.code)) {
    return true
  }

  const findModules = (permissionSetting || []).filter((fi) =>
    keyModule.includes(fi.moduleName)
  )

  let result = false
  for (let i = 0; i < findModules.length; i++) {
    const item = findModules?.[i]
    if (item?.objects?.length) {
      const filterItem = item.objects.filter(
        (fi) =>
          keyModuleObject.includes(fi.name) &&
          fi.permissions.includes(permissionAction)
      )
      if (filterItem?.length) {
        result = true
        break
      }
    }
  }

  return result
}

const canAccessFeature = ({
  currentRole,
  permissionSetting,
  keyModule = [],
  keyModuleObject = [],
  action
}: {
  currentRole?: IRole
  permissionSetting: ITenantPermissionSettingType[]
  keyModule: Array<string>
  keyModuleObject: Array<string>
  action: string
}) => {
  if (adminCanAction(currentRole?.code)) {
    return true
  }

  const findModules = (permissionSetting || []).filter((fi) =>
    keyModule.includes(fi.moduleName)
  )

  let result = false
  for (let i = 0; i < findModules.length; i++) {
    const item = findModules?.[i]
    if (item?.objects?.length) {
      const filterItem = item.objects.filter(
        (fi) => keyModuleObject.includes(fi.name) && fi.actions.includes(action)
      )
      if (filterItem?.length) {
        result = true
        break
      }
    }
  }

  return result
}

const canActionFeature = ({
  currentRole,
  permissionSetting,
  keyModule = [],
  keyModuleObject = []
}: {
  currentRole?: IRole
  permissionSetting: ITenantPermissionSettingType[]
  keyModule: Array<string>
  keyModuleObject?: Array<string>
}) => {
  if (adminCanAction(currentRole?.code)) {
    return {
      create: true,
      update: true,
      delete: true,
      owned_update: true,
      owned_delete: true
    }
  }

  const findModules = (permissionSetting || []).filter((fi) =>
    keyModule.includes(fi.moduleName)
  )

  let result = {
    create: false,
    update: false,
    delete: false,
    owned_update: false,
    owned_delete: false
  }
  for (let i = 0; i < findModules.length; i++) {
    const item = findModules?.[i]
    if (item?.objects?.length) {
      // Case create
      const filterItemCreate = item.objects.filter(
        (fi) =>
          keyModuleObject.includes(fi.name) &&
          fi.actions.includes(ACTIONS_PERMISSIONS.create)
      )
      if (filterItemCreate?.length) {
        result = {
          ...result,
          create: true
        }
      }

      // Case update
      const filterItemUpdate = item.objects.filter(
        (fi) =>
          keyModuleObject.includes(fi.name) &&
          fi.actions.includes(ACTIONS_PERMISSIONS.update)
      )
      if (filterItemUpdate?.length) {
        result = {
          ...result,
          update: true
        }
      }

      // Case owned_update
      const filterItemOwnedUpdate = item.objects.filter(
        (fi) =>
          keyModuleObject.includes(fi.name) &&
          fi.actions.includes(ACTIONS_PERMISSIONS.owned_update)
      )
      if (filterItemOwnedUpdate?.length) {
        result = {
          ...result,
          owned_update: true
        }
      }

      // Case delete
      const filterItemDelete = item.objects.filter(
        (fi) =>
          keyModuleObject.includes(fi.name) &&
          fi.actions.includes(ACTIONS_PERMISSIONS.delete)
      )
      if (filterItemDelete?.length) {
        result = {
          ...result,
          delete: true
        }
      }

      // Case owned_delete
      const filterItemOwnedDelete = item.objects.filter(
        (fi) =>
          keyModuleObject.includes(fi.name) &&
          fi.actions.includes(ACTIONS_PERMISSIONS.owned_delete)
      )
      if (filterItemOwnedDelete?.length) {
        result = {
          ...result,
          owned_delete: true
        }
      }
    }
  }

  return result
}

const accessRequisitionFeature = (
  featureSetting?: ITenantFeatureSettingType[]
) => {
  return !!featureSetting?.find((fi) => fi.key === REQUISITION)
    ?.enabling_feature
}

const accessReferralFeature = (
  featureSetting?: ITenantFeatureSettingType[]
) => {
  return !!featureSetting?.find((fi) => fi.key === REFERRAL)?.enabling_feature
}

const accessImportJobFeature = (
  featureSetting?: ITenantFeatureSettingType[]
) => {
  return !!featureSetting?.find((fi) => fi.key === IMPORT_JOB)?.enabling_feature
}

export {
  DROPDOWN_PERMISSIONS,
  ACTIONS_PERMISSIONS,
  DEFAULT_PERMISSIONS_LIST,
  PERMISSIONS_LIST,
  accessReferralFeature,
  accessRequisitionFeature,
  canAccessSetting,
  canActionSetting,
  canAccessFeature,
  canPermissionFeature,
  canActionFeature,
  accessImportJobFeature
}

export type { IDefaultPermissionsList }
